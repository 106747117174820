import { connect } from 'react-redux';

import { settingsSelector } from '../behaviors/settings';
import { configSelector } from '../behaviors/configuration';
import { searchWidgetConfigSelector } from '../behaviors/search-widget';
import { getCustomerCode } from '../behaviors/customer-code';
import Home from '../home';

const mapStateToProps = (state) => {
  let settings = settingsSelector(state);
  let config = configSelector(state);
  let searchWidgetConfig = searchWidgetConfigSelector(state);
  let customerCode = getCustomerCode(state);

  return {
    settings,
    config,
    searchWidgetConfig,
    customerCode
  };
};

const HomeContainer = connect(mapStateToProps)(Home);

export default HomeContainer;
