import React from 'react';
import { defineMessages } from 'react-intl';

import { IntlHelmet } from '../utils/intl-components';

const messages = defineMessages({
  pagetitlebasic: {
    id: 'providerlist.pagetitle.basic',
    description: 'The title of the page containing search results',
    defaultMessage: 'ProviderMatch'
  },
  pagetitlewithquery: {
    id: 'providerlist.pagetitle.withquery',
    description: 'The title of the page containing search results',
    defaultMessage: '{searchTerm} - ProviderMatch'
  },
  pagetitlewithqueryandlocation: {
    id: 'providerlist.pagetitle.withqueryandlocation',
    description: 'The title of the page containing search results',
    defaultMessage: '{searchTerm} in {location} - ProviderMatch'
  },
  pagedescriptionbasic: {
    id: 'providerlist.pagedescription.basic',
    description: 'The description of the page containing search results',
    defaultMessage: 'ProviderMatch'
  },
  pagedescriptionwithquery: {
    id: 'providerlist.pagedescription.withquery',
    description: 'The description of the page containing search results',
    defaultMessage: '{searchTerm} - ProviderMatch'
  }
});

function SearchMetas({ searchSummary }) {
  let searchTerm = searchSummary.search_description || '';
  let location = searchSummary.display_location || searchSummary.location || '';
  let titleMessageDescriptor, descriptionMessageDescriptor;
  if (searchTerm && location) {
    titleMessageDescriptor = messages.pagetitlewithqueryandlocation;
    descriptionMessageDescriptor = messages.pagedescriptionwithquery;
  } else if (searchTerm) {
    titleMessageDescriptor = messages.pagetitlewithquery;
    descriptionMessageDescriptor = messages.pagedescriptionwithquery;
  } else {
    titleMessageDescriptor = messages.pagetitlebasic;
    descriptionMessageDescriptor = messages.pagedescriptionbasic;
  }
  return (
    <IntlHelmet
      titleMessageDescriptor={titleMessageDescriptor}
      titleMessageDescriptorValues={{ searchTerm, location }}
      metaMessageDescriptors={[{
        name: 'description',
        contentMessageDescriptor: descriptionMessageDescriptor,
        contentMessageDescriptorValues: { searchTerm }
      }]}
    />
  );
}

export default SearchMetas;
