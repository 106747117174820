import React from 'react';
import _isInteger from 'lodash/isInteger';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import { defineMessages, injectIntl } from 'react-intl';
import { KyruusFormattedMessage, kyruusFormatMessage } from '@kyruus/intl';

import { withRouter } from 'react-router-dom';
import querystring from 'querystring';

import Banner from '@kyruus/banner';
import Map from '@kyruus/react-ikons/Map';

import ProviderList, { FROM_SEARCH_LIST } from '../shared/provider-list';
import NoResultsContent from './no-results';
import LoadingOverlay from '../shared/loading';
import ResultsHeader, {
  MobileResultsHeader,
  EmergencyBanner,
  EmergencyBannerMd
} from './results-header';
import FacetPanel from './facet-panel';
import { FacetChips } from './facet-chips';
import SearchFooter from './search-footer';
import SearchMetas from './search-metas';
import { SearchAlerts } from '../shared/search-alerts';
import MapSearchLinkBlock from './map-search-link-block';
import { MAP_BG_URL } from './map-search-link-block/constants';
import ErrorMessage from '../error/error';
import AvailabilityControls from '../shared/AvailabilityControls';

import {
  getPurposeOptionsByPatientRel,
  splitObjectKeys,
  hidePatientRel,
  showSearchFooterDisclaimer
} from './utils';
import AvailabilityModal from './availability-modal';
import { AvailabilityControlsContainer } from './styles';
import {
  V8_PATIENT_REL_FILTER_KEY,
  V8_PURPOSE_FILTER_KEY,
  V8_DIRECT_BOOK_CAPABLE_FILTER,
  V8_VISIBILITIES_FILTER_BASE
} from '../utils/constants';
import {
  getProviderDisplayName_V8,
  getProviderPhoneNumber_V8
} from '../provider/utils';
import { mapSearchModuleEnabled, getMapSearchURL } from '../utils/map-search';
import { mapSearchCallbackFunction } from '../map-search/lib/utils';
import { scrollToTopCCF } from '../utils/cerner';
import {
  shouldRenderDirectBookInDrawer,
  MODULES,
  isModuleEnabled
} from 'Common/config';
import { getLogWithV8Provider } from '../tracking/decorators';

import { getApptPurposeFilterProps } from '../shared/appt-purpose-filtering';

const messages = defineMessages({
  providerlistheader: {
    id: 'providerlist.header',
    description: 'Header for a list of search results ',
    defaultMessage: 'Search results'
  },
  providerlistexpandedradiuswarning: {
    id: 'providerlist.expandedradius',
    description: 'Warning message for when we increase the search radius',
    defaultMessage: `No providers found within {
        originalDistance,
        plural,
        one {# mile}
        other {# miles}
      }, showing all results for "{searchDescription}"`
  },
  bannerCloseAriaLabel1: {
    id: 'banner.close.arialLabel1',
    description:
      'action1 close icon aria label text to be read by screen reader',
    defaultMessage: 'close'
  }
});

class Search extends React.Component {
  constructor() {
    super();
    this.state = {
      toggle: false,
      isAvailabilityModalOpen: false,
      availableAppt: null,
      patientRel: '',
      purpose: null,
      purposeHasNoAvailability: null,
      patientRelHasNoAvailability: null,
      shouldHidePatientRel: false
    };

    this.handleOnChangeSwitch = this.handleOnChangeSwitch.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleConfirmModal = this.handleConfirmModal.bind(this);
    this.onPatientRelSelect = this.onPatientRelSelect.bind(this);
    this.onSelectPurpose = this.onSelectPurpose.bind(this);
    this.onClickShowAvailability = this.onClickShowAvailability.bind(this);
    this.onAvailabilityTilesLoaded = this.onAvailabilityTilesLoaded.bind(this);
  }

  componentDidMount() {
    const {
      location,
      setPatientRel,
      setPurpose,
      fetchGlobalPurposesByPatientRel,
      log,
      slotsByProviderId,
      fetchSlots,
      history
    } = this.props;

    if (slotsByProviderId) {
      // refresh slots
      Object.entries(slotsByProviderId).forEach(([key, { apptInfo }]) => {
        fetchSlots(apptInfo, [key]);
      });
    }

    const query = querystring.parse(location.search.substring(1));
    const hasAvailability =
      query.filter &&
      Array.isArray(query.filter) &&
      query.filter.length &&
      Object.assign(...splitObjectKeys(query.filter));

    const isPurpose = hasAvailability && hasAvailability[V8_PURPOSE_FILTER_KEY];

    if (isPurpose) {
      const isPatientRel = hasAvailability[V8_PATIENT_REL_FILTER_KEY];

      this.setState({
        purpose: {
          name: isPurpose
        },
        patientRel: isPatientRel,
        availableAppt: {
          patientRel: isPatientRel,
          purpose: isPurpose
        },
        toggle: true
      });

      setPatientRel(isPatientRel);
      setPurpose(isPurpose);
      isPatientRel && fetchGlobalPurposesByPatientRel(isPatientRel, log);
    }

    if (query.relationship && query.purpose) {
      // remove those params to avoid cross-contamination with other providers
      // after returning to search from a provider profile with availability params attached
      const copyOfQuery = {
        ...query
      };

      delete copyOfQuery.relationship;
      delete copyOfQuery.purpose;

      history.push(`/search?${querystring.stringify(copyOfQuery)}`);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      totalProviders,
      loading,
      searchSummary,
      history,
      getUpdatedSearch,
      location,
      searchContext,
      apptInfo,
      log,
      fetchGlobalPurposesByPatientRel
    } = this.props;

    const query = querystring.parse(location.search.substring(1));

    const locationChanged = prevProps.location.search !== location.search;
    const searchTermChanged =
      prevProps?.searchSummary?.search_query &&
      prevProps.searchSummary.search_query !== searchSummary.search_query;

    // reset state if the search term or the url changes
    if (searchTermChanged || locationChanged) {
      let newState = { providerId: null };

      const filtersReset = locationChanged && query.filter == null;
      // reset everything if the search term changed
      if (searchTermChanged || filtersReset) {
        this.props.resetAvailability();
        newState = {
          ...newState,
          availableAppt: null,
          patientRel: '',
          purpose: null,
          toggle: false
        };
      }

      this.setState(newState);
    }

    const currentDistance = parseInt(searchSummary.distance, 10);

    /**
     * If we are making a distance search and there are no results,
     * auto-expand the search radius by setting distance to 0
     */
    if (
      totalProviders !== prevProps.totalProviders ||
      loading !== prevProps.loading ||
      currentDistance !== prevProps.searchSummary.distance ||
      history !== prevProps.history ||
      getUpdatedSearch !== prevProps.getUpdatedSearch
    ) {
      if (totalProviders == 0 && !loading && currentDistance > 0) {
        const expandedRadiusSearch = getUpdatedSearch([
          {
            action: 'append',
            key: 'distance',
            value: 0
          }
        ]);
        history.push(expandedRadiusSearch, {
          originalDistance: currentDistance
        });
      }
    }

    if (searchContext !== prevProps.searchContext && apptInfo.relationship) {
      fetchGlobalPurposesByPatientRel(apptInfo.relationship, log);
    }
  }

  reset() {
    const { patientRel, purpose } = this.state;
    const { history, getUpdatedSearch, config } = this.props;
    const { schedulingVisibility } = config.visibility;
    this.props.resetAvailability();
    this.setState({
      availableAppt: null,
      patientRel: '',
      purpose: null
    });
    const filter = [
      `${V8_PATIENT_REL_FILTER_KEY}:${patientRel}`,
      `${V8_PURPOSE_FILTER_KEY}:${purpose.name}`,
      `${V8_VISIBILITIES_FILTER_BASE}.${schedulingVisibility}`
    ];
    history.push(
      getUpdatedSearch([
        { action: 'delete_key_value', key: 'filter', value: filter }
      ])
    );
  }

  handleOnChangeSwitch() {
    const { log } = this.props;
    this.setState((prev) => ({ toggle: !prev.toggle }));
    if (this.state.providerId) {
      this.setState(
        {
          isAvailabilityModalOpen: true,
          patientRel: '',
          providerId: null
        },
        () => {
          this.onPatientRelSelect('new');
        }
      );
    }
    if (this.state.availableAppt) {
      log('user_action.show.provider_availability_toggle_off');
      this.reset();
    } else {
      log('user_action.show.provider_availability_toggle_on');
      this.setState({
        isAvailabilityModalOpen: true,
        patientRel: 'new',
        providerId: null
      });
      this.onPatientRelSelect('new');
    }

    // never hide patient rel for global modal
    this.setState({
      shouldHidePatientRel: false
    });

    scrollToTopCCF();
  }

  handleEditClick() {
    const { log } = this.props;
    log('user_action.show.provider_availability_toggle_edit');
    this.setState({
      isAvailabilityModalOpen: true
    });
    scrollToTopCCF();
  }

  handleCloseModal(type) {
    const { log } = this.props;
    log(`user_action.show_available_appt_modal.${type}`);
    const { availableAppt } = this.state;
    const isAvailableAppt = !!availableAppt;

    let patientRel, purpose;

    if (isAvailableAppt) {
      patientRel = availableAppt.patientRel;
      purpose = { name: availableAppt.purpose };
    } else {
      patientRel = '';
      purpose = null;
    }

    this.setState({
      isAvailabilityModalOpen: false,
      patientRel,
      purpose,
      toggle: isAvailableAppt,
      providerId: null,
      purposeHasNoAvailability: null,
      patientRelHasNoAvailability: null
    });
  }

  handleConfirmModal() {
    const { purpose, patientRel, providerId } = this.state;
    const {
      setPurpose,
      setPatientRel,
      getUpdatedSearch,
      history,
      fetchSlots,
      config
    } = this.props;
    const { schedulingVisibility } = config.visibility;
    const availableAppt = providerId
      ? null
      : {
          patientRel: patientRel,
          purpose: purpose.name
        };

    if (availableAppt) {
      this.setState({
        isAvailabilityModalOpen: false,
        availableAppt,
        providerId: null,
        purpose: providerId ? null : purpose,
        patientRel: providerId ? null : patientRel
      });
      const filter = [
        `${V8_PATIENT_REL_FILTER_KEY}:${patientRel}`,
        `${V8_PURPOSE_FILTER_KEY}:${purpose.name}`,
        V8_DIRECT_BOOK_CAPABLE_FILTER,
        `${V8_VISIBILITIES_FILTER_BASE}.${schedulingVisibility}`
      ];
      const params = [
        {
          action: 'append',
          key: 'filter',
          value: filter
        }
      ];
      history.push(getUpdatedSearch(params));
    } else {
      this.setState({
        isAvailabilityModalOpen: false,
        providerId
      });
    }
    setPatientRel(patientRel);
    setPurpose(purpose.name);
    if (providerId) {
      fetchSlots(
        {
          relationship: patientRel,
          purpose: purpose.name
        },
        [providerId]
      );
    }
  }

  onPatientRelSelect(patientRel) {
    const { apptInfo, fetchGlobalPurposesByPatientRel, log } = this.props;
    const { providerId, availableAppt } = this.state;
    log('user_action.provider_availability_type_selected', {
      patient_rel: patientRel
    });
    this.setState(
      (state) => {
        const newStateFragment = {
          patientRel,
          purpose: null,
          purposeHasNoAvailability: false
        };

        if (
          state.providerId != null &&
          this.props.availablePurposesByProviderId != null &&
          this.props.availablePurposesByProviderId[this.state.providerId] !=
            null
        ) {
          const availablePurposes =
            this.props.availablePurposesByProviderId[this.state.providerId];

          newStateFragment.patientRelHasNoAvailability =
            !availablePurposes[patientRel].size;
        }
        return newStateFragment;
      },
      () =>
        (!providerId || availableAppt) &&
        !apptInfo.purposeOptions[patientRel].length &&
        fetchGlobalPurposesByPatientRel(patientRel, log)
    );
  }

  onSelectPurpose(purpose) {
    this.props.log('user_action.appt_purpose_type_selected', {
      appt_purpose: purpose
    });

    if (this.state.providerId) {
      const availablePurposes =
        this.props.availablePurposesByProviderId[this.state.providerId];

      this.setState({
        purpose,
        purposeHasNoAvailability: !availablePurposes[this.state.patientRel].has(
          purpose.name
        )
      });
    }

    this.setState({ purpose });
  }

  onClickShowAvailability(provider) {
    const { config, log, fetchPurposesByProviderId } = this.props;
    log('user_action.show.provider_availability_tile', {
      provider_id: provider.id
    });
    const shouldHidePatientRel = hidePatientRel(
      provider,
      config.direct_book.hide_patient_rel_for_locations
    );
    const defaultPatientRel = shouldHidePatientRel ? 'established' : '';
    if (this.state.providerId) {
      this.setState({
        isAvailabilityModalOpen: true,
        providerId: provider.id,
        patientRel: defaultPatientRel,
        purpose: null,
        shouldHidePatientRel: shouldHidePatientRel
      });
    } else {
      this.setState({
        isAvailabilityModalOpen: true,
        providerId: provider.id,
        patientRel: defaultPatientRel,
        shouldHidePatientRel: shouldHidePatientRel
      });
    }

    fetchPurposesByProviderId(provider.id, log);
    scrollToTopCCF();
  }

  shouldOpenDirectBookSameWindow() {
    const { config } = this.props;
    return isModuleEnabled(config, MODULES.DIRECT_BOOK);
  }

  onAvailabilityTilesLoaded(availabilitiesCount, provider_id) {
    const { providerId, log } = this.props;
    if (this.state.providerId === providerId) {
      log('user_action.show_show_available_appt_times', {
        provider_id,
        count: availabilitiesCount.length
      });
    }
  }

  getPhoneNumberForSelectedProvider(selectedProviderId, providers) {
    if (selectedProviderId) {
      const selectedProvider = providers.find(
        (provider) => selectedProviderId === provider.id
      );

      if (selectedProvider) {
        return getProviderPhoneNumber_V8(selectedProvider);
      }
    }

    return null;
  }

  getSelectedProvider() {
    return this.props.providers.find((provider) => {
      return this.state.providerId === provider.id;
    });
  }

  render() {
    const {
      searchSummary,
      config,
      facets,
      getUpdatedSearch,
      providers,
      totalProviders,
      totalPages,
      suggestions,
      topSpecialties,
      log,
      loading,
      slotsCountLoading,
      error,
      showMobileFacets,
      toggleMobileFacets,
      distanceSort,
      sortOptions,
      apptInfo,
      availabilityButtonsConfig,
      location,
      searchProviderIds,
      slotsByProviderId,
      slotsStatusByProviderId = {},
      history,
      tokens,
      customerCode,
      productName,
      alerts,
      intl
    } = this.props;

    const showLoadingOverlay = loading || slotsCountLoading;

    const handleTimeSlotsClick = (date, time, provider_id, href) => {
      const provider = providers.find((p) => p.id === provider_id);
      getLogWithV8Provider(log, provider)(
        'user_action.provider_timeslot_selected_search',
        {
          date,
          time
        }
      );
      if (shouldRenderDirectBookInDrawer(config)) {
        provider &&
          history.push(href, {
            provider: {
              location,
              provider
            }
          });
      }
    };

    const handleViewMoreClick = (provider_id, href) => {
      const provider = providers.find((p) => p.id === provider_id);

      getLogWithV8Provider(
        log,
        provider
      )(
        'user_action.search_results.provider_card.availability_tiles.view_all_appointments'
      );

      if (shouldRenderDirectBookInDrawer(config)) {
        provider &&
          history.push(href, {
            provider: {
              location,
              provider
            }
          });
      }
    };

    const shouldDisplayAvailability = config.display_availability_in_search;
    if (error) {
      return (
        <div>
          <LoadingOverlay loading={loading || slotsCountLoading} />
          <ErrorMessage />
        </div>
      );
    }

    let providerContent;

    const {
      availableAppt,
      patientRel,
      isAvailabilityModalOpen,
      purpose,
      shouldHidePatientRel
    } = this.state;

    const originalDistance = location.state && location.state.originalDistance;

    const bookUrlTarget = this.shouldOpenDirectBookSameWindow()
      ? '_self'
      : undefined;

    if (totalProviders == 0) {
      if (!_isEmpty(suggestions) && !loading) {
        log('user_action.search_results.spell_suggestion_available', {
          suggestions: _map(suggestions, 'display_text').toString(),
          search_query: searchSummary.search_query
        });
      }
      providerContent = (
        <NoResultsContent
          suggestions={suggestions}
          topSpecialties={topSpecialties}
          getUpdatedSearch={getUpdatedSearch}
          searchQuery={searchSummary.search_query}
          log={log}
          history={history}
        />
      );
    } else {
      providerContent = (
        <ProviderList
          providers={providers}
          customerCode={customerCode}
          productName={productName}
          config={config}
          searchSummary={searchSummary}
          log={log}
          slots={slotsByProviderId}
          slotsStatus={slotsStatusByProviderId}
          onClickShowAvailability={this.onClickShowAvailability}
          availabilityLinkTarget={bookUrlTarget}
          onTimeSlotsClick={handleTimeSlotsClick}
          onViewMoreClick={handleViewMoreClick}
          onAvailabilityTilesLoaded={this.onAvailabilityTilesLoaded}
          profileSummaryParams={{
            from: FROM_SEARCH_LIST
          }}
          tokens={tokens}
        />
      );
    }

    const showDisclaimer = showSearchFooterDisclaimer(providers);

    const showEmergencyMd =
      config.emergency_disclaimer_md && config.emergency_disclaimer_md.enabled;

    const bookOnlineUrlExists = (providers || []).some((provider) =>
      Boolean(provider.book_online_url)
    );

    const shouldDisplayReadOnlyAvailability =
      providers && config.enable_read_only_availability;

    const isGlobalApptInfoSelected =
      apptInfo?.relationship && apptInfo?.purpose;

    const showGlobalAvailabilityControls =
      bookOnlineUrlExists ||
      shouldDisplayReadOnlyAvailability ||
      // prevent dead end when global purpose is selected and no providers are returned
      (isGlobalApptInfoSelected && !searchProviderIds.length);

    const mapSearchUrl = getMapSearchURL(searchSummary.query_string);

    const primaryMlocFacet =
      facets &&
      facets.find(
        (f) => f.field === 'locations.primary_marketable_location_id'
      );

    const searchFacetChips = config?.search_results_page?.search_facet_chips;

    const shouldShowMapLink =
      totalProviders > 0 &&
      mapSearchModuleEnabled(config) &&
      primaryMlocFacet &&
      primaryMlocFacet.total > 0;

    return (
      <main className="search-container mt-m">
        <div>
          <SearchMetas searchSummary={searchSummary} />
          {showEmergencyMd ? (
            <EmergencyBannerMd
              bannerConfig={config.emergency_disclaimer_md}
              action1AriaLabel={kyruusFormatMessage(
                intl,
                messages.bannerCloseAriaLabel1
              )}
            />
          ) : (
            <EmergencyBanner
              config={config}
              action1AriaLabel={kyruusFormatMessage(
                intl,
                messages.bannerCloseAriaLabel1
              )}
            />
          )}
          <LoadingOverlay loading={showLoadingOverlay} />
          {shouldShowMapLink && (
            <MapSearchLinkBlock
              backgroundImgUrl={MAP_BG_URL}
              linkUrl={mapSearchUrl}
              forMobile={true}
              onClick={(e) => {
                mapSearchCallbackFunction(e, log, mapSearchUrl, history);
              }}
            >
              <Map size="60px" />
            </MapSearchLinkBlock>
          )}
        </div>
        <section className="row mt-m">
          <div>
            <h1 className="sr-only">
              <KyruusFormattedMessage {...messages.providerlistheader} />
            </h1>
            <MobileResultsHeader
              totalProviders={totalProviders}
              toggleMobileFacets={toggleMobileFacets}
            />
          </div>

          <aside className="col-md-3">
            <FacetPanel
              showMobileFacets={showMobileFacets}
              searchSummary={searchSummary}
              sortOptions={sortOptions}
              totalProviders={totalProviders}
              log={log}
              getUpdatedSearch={getUpdatedSearch}
              toggleMobileFacets={toggleMobileFacets}
              facets={facets}
              distanceSort={distanceSort}
              showLocationFacet={true}
              config={config}
              history={history}
              tokens={tokens}
            />
          </aside>

          <section
            className="col-md-9 results"
            aria-busy={loading ? 'true' : 'false'}
          >
            <SearchAlerts config={config} searchAlerts={alerts} />
            {_isInteger(totalProviders) && (
              <ResultsHeader
                totalProviders={totalProviders}
                searchSummary={searchSummary}
                sortOptions={sortOptions}
                log={log}
                getUpdatedSearch={getUpdatedSearch}
                isLoadingOverlayShown={showLoadingOverlay}
              />
            )}

            {!loading && originalDistance && (
              <Banner
                data-testid="ExpandedSearchRadiusBanner"
                className="mt-m mb-m"
                type="warning"
                icon="!"
              >
                <KyruusFormattedMessage
                  {...messages.providerlistexpandedradiuswarning}
                  values={{
                    originalDistance,
                    searchDescription: searchSummary.search_description
                  }}
                >
                  {(text) => <span role="status">{text}</span>}
                </KyruusFormattedMessage>
              </Banner>
            )}

            {shouldDisplayAvailability &&
              !this.props.hasFetchingSlotCountsFailed &&
              (!loading || searchSummary.search_query) &&
              showGlobalAvailabilityControls && (
                <AvailabilityControlsContainer>
                  <AvailabilityControls
                    handleEdit={this.handleEditClick}
                    handleToggle={this.handleOnChangeSwitch}
                    selectedAppointment={availableAppt}
                  />
                </AvailabilityControlsContainer>
              )}
            {searchFacetChips && (
              <FacetChips
                facets={facets}
                getUpdatedSearch={getUpdatedSearch}
                searchSummary={searchSummary}
              />
            )}

            <AvailabilityModal
              patientRel={
                (availableAppt && !patientRel && availableAppt.patientRel) ||
                patientRel
              }
              purpose={
                (availableAppt &&
                  !patientRel &&
                  !purpose && { name: availableAppt.purpose }) ||
                purpose
              }
              isOpen={isAvailabilityModalOpen}
              onPatientRelSelect={this.onPatientRelSelect}
              onSelectPurpose={this.onSelectPurpose}
              handleCloseModal={this.handleCloseModal}
              handleConfirmModal={this.handleConfirmModal}
              patientRelOptions={
                this.state.providerId
                  ? getPurposeOptionsByPatientRel(
                      this.getSelectedProvider(),
                      config.visibility.schedulingVisibility
                    )
                  : apptInfo.purposeOptions
              }
              providerDisplayName={
                this.state.providerId &&
                getProviderDisplayName_V8(this.getSelectedProvider())
              }
              providerPhone={this.getPhoneNumberForSelectedProvider(
                this.state.providerId,
                providers
              )}
              availabilityButtonsConfig={availabilityButtonsConfig}
              isPurposeLoading={apptInfo.loading}
              log={log}
              patientRelHasNoAvailability={
                this.state.patientRelHasNoAvailability
              }
              purposeHasNoAvailability={this.state.purposeHasNoAvailability}
              hidePatientRel={shouldHidePatientRel}
              apptPurposeFilterProps={getApptPurposeFilterProps({
                intl,
                config
              })}
            />

            {providerContent}
            <SearchFooter
              searchSummary={searchSummary}
              showDisclaimer={showDisclaimer}
              totalPages={totalPages}
              getUpdatedSearch={getUpdatedSearch}
              config={config}
              log={log}
            />
          </section>
        </section>
      </main>
    );
  }
}

export default withRouter(injectIntl(Search));
