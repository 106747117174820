import React from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { KyruusFormattedMessage } from '@kyruus/intl';
import _startCase from 'lodash/startCase';

import { StyledChipsContainer } from './styles';
import Chip from '@kyruus/chip';

const AppliedChipsBase = ({
  appliedTerms,
  filter,
  getUpdatedSearch,
  history
}) => {
  return appliedTerms
    .filter((appliedTerm) => {
      return appliedTerm?.applied;
    })
    .map((appliedTerm) => {
      const facetLabelMessageDescriptor = {
        id: `field.name.${filter}`,
        defaultMessage: _startCase(filter),
        description: 'The name displayed above the facet'
      };
      const baseFacetMessageDescriptor = {
        id: `field.value.${filter}.${appliedTerm.value}`,
        defaultMessage: `${appliedTerm.value}`,
        description: 'Facet for provider search'
      };
      return (
        <Chip
          color="primary"
          label={
            <span>
              <KyruusFormattedMessage {...facetLabelMessageDescriptor} />
              :&nbsp;
              <KyruusFormattedMessage {...baseFacetMessageDescriptor} />
            </span>
          }
          key={`${filter}:${appliedTerm.value}`}
          onDelete={() =>
            history.push(
              getUpdatedSearch([
                {
                  action: 'delete_key_value',
                  key: 'filter',
                  value: `${filter}:${appliedTerm.value}`
                }
              ])
            )
          }
        />
      );
    });
};

const LocationChip = ({ searchSummary, getUpdatedSearch, history }) => {
  const locationFacetMessageDescriptor = {
    id: 'facet_chips.location',
    description: 'Summary of search location information',
    defaultMessage:
      'Within {distance, plural, =0 {any miles} one {# mile} other {# miles}} of {location}',
    values: {
      location: searchSummary.display_location,
      distance: searchSummary.distance
    }
  };
  return (
    <Chip
      color="primary"
      data-testid="filter-chip-location"
      label={<KyruusFormattedMessage {...locationFacetMessageDescriptor} />}
      onDelete={() =>
        history.push(
          getUpdatedSearch([
            { action: 'delete_key', key: 'display_location' },
            { action: 'delete_key', key: 'location' },
            { action: 'delete_key', key: 'distance' }
          ])
        )
      }
    />
  );
};

const AppliedChips = withRouter(injectIntl(AppliedChipsBase));
const LocationAppliedChip = withRouter(injectIntl(LocationChip));

const isFacetLocation = (facet) =>
  facet.field === 'location' ||
  facet.field === 'locations.primary_marketable_location_id';

const hasTermsAvailable = (facet) => !!facet?.terms?.length;

const shouldShowFacet = (facet) =>
  !isFacetLocation(facet) && hasTermsAvailable(facet);

function FacetChips({ facets, getUpdatedSearch, searchSummary }) {
  const allFacetChips = facets.filter(shouldShowFacet).map((facet) => {
    return (
      <AppliedChips
        appliedTerms={facet.terms}
        filter={facet.field}
        getUpdatedSearch={getUpdatedSearch}
        key={facet.field}
      />
    );
  });

  return (
    <StyledChipsContainer>
      {searchSummary.display_location && (
        <LocationAppliedChip
          searchSummary={searchSummary}
          getUpdatedSearch={getUpdatedSearch}
        />
      )}
      {allFacetChips}
    </StyledChipsContainer>
  );
}

export { FacetChips, shouldShowFacet };
